<template>
  <th-page-wrapper>
    <th-datatable
      ref="table"
      sortable
      no-meta-check
      :headers="headers"
      :show-operations="false"
      :resource-limit="1000"
      :resource-query="resourceQuery"
      :meta-options="metaOptions"
      :buttons="computedButtons"
      :custom-resource="customResource"
      :locale="locale"
      show-filter
      :search-filters="filtersList"
      resource="analytics"
      route-base="/reports/financial_accounting/payments"
      do-route-filters
      prune-search-filters
      headers-filterable
      :headers-config="headersConfig"
      @headers-config="handleHeadersConfig"
      @loading-error="handleLoadingError"
    />
  </th-page-wrapper>
</template>

<script>
import qs from 'qs'
import pick from 'just-pick'
import safeGet from 'just-safe-get'
import th from '@tillhub/javascript-sdk'
import { mapGetters } from 'vuex'
import datatableHeadersConfig from '@/mixins/datatable-headers-config'
import { getTypesLabels } from '@/views/accounting/payment-options/helpers'
import { applyFiltersBeforeRouteEnter, getRangeFor } from '@/utils/date'
import CurrencyInput from '@/components/inputs/currency-input'
import { useExportsStore } from '@/store/exports'

export default {
  metaInfo() {
    return {
      title: this.$t('pages.payments.title')
    }
  },
  beforeRouteEnter: (to, from, next) => {
    // doing stuff here is very dangerous as it might lead to infinite route loops
    applyFiltersBeforeRouteEnter({ path: to.path, query: to.query, next })
  },
  beforeRouteUpdate(to, from, next) {
    // as UX enhancement we are going to persist some of the queries
    const elegibleObj = pick(safeGet(qs.parse(to.query), 'filter') || {}, [
      'date',
      'branch_group'
    ])
    this.$emit('route-filter', elegibleObj)
    next()
  },
  setup() {
    const { headersConfig, handleHeadersConfig } = datatableHeadersConfig(
      'settings.headerFilters.reports.financial_accounting.payments'
    )
    return {
      headersConfig,
      handleHeadersConfig
    }
  },
  data() {
    return {
      isLegacy: true,
      resources: {},
      paymentTypesLabels: getTypesLabels(this),
      headers: [
        {
          field: 'date',
          label: this.$t('pages.payments.date'),
          formatter: (row) => {
            if (!row.date) return '--'
            return this.$date.formatDateTimeWithTimezone(row.date)
          },
          sortType: 'date',
          minWidth: 150,
          truncate: true,
          fixed: 'left'
        },
        {
          field: 'amount',
          label: this.$t('pages.payments.amount'),
          formatter: (row) =>
            row.amount && this.$formatCurrency(row.amount, row.currency),
          sortType: 'currency',
          fixed: 'left',
          minWidth: 120,
          truncate: true
        },
        {
          field: 'payment_option',
          label: this.$t('pages.payments.payment_option'),
          formatter: (row) => row.payment_option,
          minWidth: 150,
          truncate: true
        },
        {
          field: 'payment_type',
          label: this.$t('pages.payments.payment_type'),
          formatter: (row) =>
            this.paymentTypesLabels[row.payment_type] || row.payment_type,
          minWidth: 150,
          truncate: true
        },
        {
          field: 'transaction_number',
          label: this.$t('pages.payments.transaction_number'),
          formatter: (row) => row.transaction_number,
          sortType: 'number',
          minWidth: 180,
          truncate: true
        },
        {
          field: 'balance_number',
          label: this.$t('pages.payments.balance_number'),
          formatter: (row) => row.balance_number,
          sortType: 'number',
          minWidth: 160,
          truncate: true
        },
        {
          field: 'branch_number',
          label: this.$t('pages.payments.branch_number'),
          formatter: (row) => row.branch_number,
          minWidth: 150,
          truncate: true
        },
        {
          field: 'register_number',
          label: this.$t('pages.payments.register_number'),
          formatter: (row) => row.register_number,
          minWidth: 160,
          truncate: true
        },
        {
          field: 'cashier_number',
          label: this.$t('pages.payments.cashier_number'),
          formatter: (row) => row.cashier_number,
          sortType: 'number',
          minWidth: 150,
          truncate: true
        },
        {
          field: 'customer_number',
          label: this.$t('pages.payments.customer_number'),
          formatter: (row) => row.customer_number,
          minWidth: 170,
          truncate: true
        }
      ]
    }
  },
  computed: {
    ...mapGetters({
      currentLocation: 'Config/getCurrentLocation',
      branchNumber: 'Config/getCurrentBranchNumber',
      timeZone: 'Config/getTimeZone',
      locale: 'Config/getLocale',
      defaultDateSelected: 'Config/getDefaultDateSelected',
      defaultCurrency: 'Config/getCurrentDefaultCurrency'
    }),
    resourceQuery() {
      return {
        legacy: this.isLegacy,
        query: {
          branch_number: this.branchNumber
        }
      }
    },
    metaOptions() {
      return {
        legacy: this.isLegacy,
        query: {
          branch_number: this.branchNumber
        }
      }
    },
    customResource() {
      return th.analyticsHandlersV1({ timeout: 90000 }).analytics.reports
        .AnalyticsReportsPayments
    },
    buttons() {
      return [
        {
          type: 'custom_export',
          scopes: ['reports_financial_accounting:payments:export'],
          clickHandler: ({ resourceOptions }) => {
            this.handleExport({ resourceOptions })
          }
        }
      ]
    },
    computedButtons() {
      return this.buttons.filter((b) =>
        b.scopes ? this.$checkPermissions({ scopes: b.scopes }) : true
      )
    },
    filtersList() {
      return [
        {
          name: 'transaction_number',
          type: 'input',
          label: this.$t('pages.payments.all.filters.transaction_number.label'),
          placeholder: this.$t('common.inputs.placeholders.custom_id')
        },
        {
          name: 'balance_number',
          type: 'input',
          label: this.$t('pages.payments.all.filters.balance_number.label'),
          placeholder: this.$t('common.inputs.placeholders.number')
        },
        {
          name: 'payment_option',
          type: 'select',
          placeholder: this.$t('common.inputs.placeholders.select'),
          label: this.$t('pages.payments.all.filters.payment_option.label'),
          options: this.mapOptions(this.resources.paymentOptions),
          filterable: true
        },
        {
          name: 'payment_type',
          type: 'select',
          placeholder: this.$t('common.inputs.placeholders.select'),
          label: this.$t('pages.payments.all.filters.payment_type.label'),
          options: this.mapOptions(this.resources.paymentOptions, 'type'),
          filterable: true
        },
        {
          name: 'register_number',
          type: 'select',
          placeholder: this.$t('common.inputs.placeholders.select'),
          label: this.$t('pages.payments.all.filters.register_number.label'),
          options: this.registers,
          filterable: true
        },
        {
          name: 'branch_group',
          type: 'remote-search-select',
          doInitialFetch: true,
          label: this.$t('pages.reports.statistics.all.branch_group'),
          resource: 'branchGroups',
          filterable: true,
          optionsValue: 'id',
          disabled: !!this.currentLocation,
          computeName: this.$formatBranch,
          modifyQuery: (q) => ({
            q,
            deleted: false
          })
        },
        {
          name: 'change',
          type: 'range',
          component: CurrencyInput,
          label: this.$t('pages.payments.all.filters.change.label'),
          currency: this.defaultCurrency,
          locale: this.locale,
          min: this.resources.range?.min_change || null,
          max: this.resources.range?.max_change || null,
          formatValue: this.formatCurrencyRange,
          filterable: true
        },
        {
          name: 'amount',
          type: 'range',
          component: CurrencyInput,
          label: this.$t('pages.payments.all.filters.amount.label'),
          currency: this.defaultCurrency,
          locale: this.locale,
          min: this.resources.range?.min_amount || null,
          max: this.resources.range?.max_amount || null,
          formatValue: this.formatCurrencyRange,
          filterable: true
        },
        {
          name: 'date',
          type: 'daterange',
          prop: ['start', 'end'],
          label: this.$t('pages.payments.all.filters.date.label'),
          closable: false,
          noFutureDates: true,
          formatValue: (value) => this.$date.formatDateRange(value),
          default: getRangeFor[this.defaultDateSelected]?.(),
          modifyFilter: (filterObject) => ({
            start: filterObject.start,
            end: filterObject.end
          })
        }
      ]
    },
    registers() {
      const { registers = [] } = this.resources
      return Array.from(registers)
        .filter((r) => r.deleted !== true)
        .filter(
          (r) => !this.currentLocation || r.branch === this.currentLocation
        )
        .filter((r) => Number.isFinite(r.register_number)) // needed to prevent odd default behavior
        .map((r) => ({
          value: r.register_number,
          label: this.$formatRegister(r),
          key: r.id
        }))
    }
  },
  async mounted() {
    await this.fetchResources()
    await this.fetchRangeFiltersData()

    this.$emitter.on('refresh-requested', () => {
      this.$refs.table.refresh()
    })
  },
  beforeUnmount() {
    this.$emitter.off('refresh-requested')
  },
  methods: {
    formatCurrencyRange({ from, to }) {
      if (Number.isFinite(+from) && Number.isFinite(+to)) {
        const fromCurrency = this.$formatCurrency(from, this.defaultCurrency)
        const toCurrency = this.$formatCurrency(to, this.defaultCurrency)
        return `${fromCurrency} - ${toCurrency}`
      }
      return null
    },
    handleLoadingError(err) {
      this.$logException(err, {
        trackError: false,
        message: this.$t('common.error.action.read.multiple', {
          resources: this.$t('pages.payments.title')
        })
      })
    },
    async fetchResources() {
      const {
        branchesV1 = [],
        registers = [],
        paymentOptions = []
      } = await this.$resourceFetch('branchesV1', 'registers', 'paymentOptions')
      this.resources = { branches: branchesV1, registers, paymentOptions }
    },
    async fetchRangeFiltersData() {
      try {
        const { data } = await th
          .analytics({ timeout: 90000 })
          .payments()
          .meta(this.metaOptions)
        this.resources.range = data
      } catch (err) {
        this.$logException(err, { trackError: false })
        return
      }
    },
    async handleExport({ resourceOptions }) {
      const query = {
        ...this.resourceQuery,
        ...resourceOptions.query,
        format: 'csv',
        filename_prefix: this.$t('pages.payments.title'),
        timezone: this.timeZone || 'Europe/Berlin'
      }

      try {
        const {
          data
        } = await th
          .analyticsHandlersV1()
          .analytics.reports.AnalyticsReportsPayments.getAll({ query })

        const exportId = data?.[0]?.correlationId
        if (!exportId) {
          throw new Error(`Response data or correlation ID is missing`)
        }

        useExportsStore().setNewExport({
          exportId,
          payload: {
            originKey: 'pages.payments.title',
            date: new Date(),
            action: {
              entity: 'analyticsHandlersV1',
              path: 'analytics.reports.AnalyticsReportsPayments',
              handler: 'getAll',
              query
            }
          }
        })
      } catch (err) {
        this.$logException(err, {
          message: this.$t('notifications.exports.error.text', {
            entity: this.$t('pages.payments.title')
          })
        })
      }
    },
    mapOptions(list = [], dataKey = 'name') {
      return list
        .map((item) => ({
          value: item[dataKey],
          label: this.paymentTypesLabels[item[dataKey]] || item[dataKey]
        }))
        .filter(
          (v, i, a) =>
            a.findIndex((t) => ['value'].every((k) => t[k] === v[k])) === i
        ) // Filter out duplicates
    }
  }
}
</script>
